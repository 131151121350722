import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSelect from "../../components/common/SHSelect";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";
import SafetyEduRgstModal from "../safetyEducationManagement/modal/SafetyEduRgstModal";
import SHSearch from '../component/SHSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHIconButton from "../../components/common/SHIconButton";
import SHInput from "../../components/common/SHInput";
import SHDatePicker from "../../components/common/SHDatePicker";
import CustomDatePicker from "../component/ui/DatePicker";
import SHTreeView from "../../components/common/SHTreeView";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import WrkPrmtRgstModal from "./modal/WrkPrmtRgstModal";
import SmrtTbmDtlModal from "./modal/SmrtTbmDtlModal";

type Props = {};

Modal.setAppElement('#root');
const SmartTBMManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const defaultUser = getLoginUser();
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const siteIdRef = useRef<any>();
    const [data, setData] = useState<any>()
    const [wrkDt, setWrkDt] = useState<string>(CmmnFn.fn_getCurrentDate());
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const prodRef = useRef<any>();
    const [statData, setStatData] = useState<any>({
        totTbmCnt: 0,
        totTbmYCnt: 0,
        totTbmNCnt: 0,

    });

    const [wrkrCnt, setWrkrCnt] = useState<number>(0);
    const [wrkrTbmYCnt, setWrkrTbmYCnt] = useState<number>(0);
    const [wrkrTbmNCnt, setWrkrTbmNCnt] = useState<number>(0);


    const [firstRowData, setFirstRowData] = useState<any>(null);
    const {spinner} = useContext(ProgressContext);
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const [tableDataList, setTableDataList] = useState<any>([]);


    useEffect(() => {
        setData({cmpnySeq: defaultUser.authGrpId === 'HEAD' ? '' : defaultUser.blngCmpnySeq})
    }, [])
    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (wrkDt) {
            fn_search();
            if(CmmnFn.isSmSite()){
                restapi.retrievePrmtWrkrDataList({wrkDt:wrkDt, siteId:user?.blngSiteId || defaultUser.blngSiteId}).then((tbmRsltData: any) => {
                    setWrkrCnt(tbmRsltData.length);
                })
            }

        }
    }, [wrkDt])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])
    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        console.log('datatable: ', datatable);
        datatable?.draw();

    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_endWork = (wrkPrmtId?: number) => {

        console.log('data: ', data);
        if (!data.cmpnySeq) {
            toast.warn('업체를 선택해 주십시오')
            return;
        }

        if (!wrkDt) {
            toast.warn('일자를 선택해 주십시오');
            return;
        }

        if (firstRowData.calTotWrkrCnt !== firstRowData.tbmCmpltCnt) {
            toast.warn('작업상태가 작업중 일때만 종료 할 수 있습니다.')
            return;
        }

        console.log('tableDataList: ', tableDataList);
        if (!window.confirm('전체 작업완료 하시겠습니까?')) {
            return;
        }

        let param: any = {siteId: user?.blngSiteId || defaultUser.blngSiteId, cmpnySeq: data.cmpnySeq, wrkDt: wrkDt};

        if (wrkPrmtId) {
            param.wrkPrmtId = wrkPrmtId;
        }

        console.log('param: ', param);
        spinner.start();
        restapi.endWork(param).then((rsltCnt: number) => {
            if (rsltCnt > 0) {
                toast.success(messageCodeDic['006'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }

    return (
        <Container fluid className="bg-white px-4 pt-4 sh-text-font_primary">
            <SubMenuTitle title={'스마트 TBM 관리'} parentMenu={'현장안전관리'}/>

            <SmrtTbmDtlModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                             siteId={user?.blngSiteId || defaultUser.blngSiteId} cmpnySeq={defaultUser.authGrpId !=='HEAD' ? '' : ''}
                             detailData={detailData}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="sh-font-SP sh-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle">

                                    <th className="sh-bg-th" style={{width: '10%'}}>업체</th>
                                    <td style={{width: '23%'}}>
                                        <SHInput type={'hidden'} name={'siteId'}
                                                  value={user?.blngSiteId || defaultUser.blngSiteId}></SHInput>
                                        <SHInput type={'hidden'} name={'prcsStatCd'} value={'S001004'}></SHInput>
                                        {CmmnFn.isSmSite() &&
                                            <>
                                            <SHInput type={'hidden'} name={'isForSmSiteTbm'} value={'true'}></SHInput>
                                            <SHInput type={'hidden'} name={'isLooseTbm'} value={'true'}></SHInput>
                                            </>
                                        }
                                        <SHSelect initOptionGb={'all'} name={'cmpnySeq'}
                                                   selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId}}
                                                   codeGb={'siteCmpny'}
                                                   className="sh-form select w-100"
                                                   onChange={fn_setFormData}
                                                   setIsCodeDataLoaded={setIsCodeDataLoaded}
                                                   value={data?.cmpnySeq}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width: '10%'}}>일자</th>
                                    <td style={{width: '23%'}} ref={prodRef}>
                                        <SHDatePicker className={"text-center w-100"} readOnly={true}
                                                       value={wrkDt} name={'wrkDt'} onClick={fn_datePopupOpen}
                                                       onChange={fn_setFormData}
                                                       calendar={fn_datePopupOpen}
                                        />
                                        {isDatePopupOpen
                                            ? <div className='absolute top-155 right-80'>
                                                <CustomDatePicker setIsDatePopupOpen={setIsDatePopupOpen}
                                                                  prodRef={prodRef}
                                                                  setDateView={setWrkDt}
                                                />
                                            </div>
                                            : null}
                                    </td>

                                </tr>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="sh-bg-th" style={{width: '10%'}}>TBM 여부</th>
                                    <td style={{width: '23%'}}>
                                        <select name={'tbmYn'} className={'form-select'} onChange={fn_setFormData}
                                                value={data?.tbmYn}>
                                            <option value={''}>전체</option>
                                            <option value={'Y'}>Y</option>
                                            <option value={'N'}>N</option>
                                        </select>
                                    </td>
                                    <th className="sh-bg-th" style={{width: '10%'}}>공종</th>
                                    <td style={{width: '23%'}}>
                                        <SHSelect initOptionGb={'all'} name={'wrkTypeCd'}
                                                   codeGb={'siteMngCd'} className={'form-select'}
                                                   onChange={fn_setFormData}
                                                   selParam={{
                                                       siteId: user?.blngSiteId || defaultUser.blngSiteId,
                                                       manageCode: 'M001001'
                                                   }}
                                                   value={data?.wrkTypeCd}></SHSelect>
                                    </td>
                                    <th className="sh-bg-th" style={{width: '10%'}}>작업상태</th>
                                    <td style={{width: '23%'}}>
                                        <SHSelect className={"form-select sh-fs-b3"} initOptionGb={'all'}
                                                   name={'wrkStatCd'} codeGb={'cmmn'} codeGrpId={'S004'}
                                                   onChange={(e) => {
                                                       fn_setFormData(e);
                                                   }}
                                                   value={data?.wrkStatCd}/>
                                    </td>

                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>

                <div className="rounded-[5px] border mb-3 pl-23">
                    <Row style={{height: '70px'}}>
                        <Col>
                            <Row className="sh-bg-accent w-100 text-center sh-text-white py-1  mt-2 rounded-t-lg">
                                <Col>총 공종별 TBM 개수</Col>
                                <Col>TBM Y</Col>
                                <Col>TBM <span className={'text-red-600'}>N</span></Col>
                            </Row>
                            <Row className="w-100 text-center py-1">
                                <Col>{statData.totTbmCnt}</Col>
                                <Col>{statData.totTbmYCnt}</Col>
                                <Col>{statData.totTbmNCnt}</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="sh-bg-accent w-100 text-center sh-text-white py-1  mt-2 rounded-t-lg">
                                <Col>총 출역인원</Col>
                                <Col>TBM Y</Col>
                                <Col>TBM <span className={'text-red-600'}>N</span></Col>
                            </Row>
                            <Row className="w-100 text-center py-1">
                                <Col>{wrkrCnt}</Col>
                                <Col>{wrkrTbmYCnt}</Col>
                                <Col>{wrkrTbmNCnt}</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                    {!CmmnFn.isSmSite() &&
                        <SHIconButton kind={'normal'} marginRight={'5px'} onClick={() => fn_endWork()}>전체 작업완료
                        </SHIconButton>
                    }


                </div>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/wrkPrmt/retrieveWrkPrmtDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "wrkTypeNm", title: "공종"},
                            {data: "todayWrkCntnts", title: "금일 작업사항 내용", render: (data: any, type: any, row: any) => {
                                    if(data){
                                        return data;
                                    }else{
                                        return row.istrctns;
                                    }

                                }},
                            {data: "calTotWrkrCnt", title: "총인원"},
                            {
                                data: "totWrkrCntYn",
                                title: "TBM 여부",
                                textAlign: 'center', render: (data: any, type: any, row: any) => {
                                    if(row.wrkStatNm == '작업대기'){
                                        return 'N';
                                    }else{
                                        return 'Y';
                                    }

                                }

                            },
                            {
                                data: "wrkStatNm", title: "작업상태", textAlign: 'center'
                            },
                            {
                                title: "기능",
                                type: "button",
                                className: "text-center",
                                buttons: [{
                                    label: "<label class='rounded cursor-pointer p-1 btn-secondary align-self-center min-w-[60px]'>작업완료</label>",
                                    onClick: (v: any, dt: any) => {
                                        console.log('v: ', v);
                                        let param: any = {
                                            siteId: user?.blngSiteId || defaultUser.blngSiteId,
                                            cmpnySeq: v.cmpnySeq,
                                            wrkDt: wrkDt,
                                            wrkPrmtId: v.wrkPrmtId
                                        };

                                        if (v.wrkStatNm !== '작업중') {
                                            toast.warn('작업상태가 작업중인 경우에만 작업완료가 가능 합니다.');
                                            return;
                                        }

                                        if(CmmnFn.isSmSite()){
                                            return;
                                        }

                                        if (!window.confirm('해당 작업을 완료처리 하시겠습니까?')) {
                                            return;
                                        }
                                        spinner.start();
                                        restapi.endWork(param).then((rsltCnt: number) => {
                                            if (rsltCnt > 0) {
                                                toast.success(messageCodeDic['006'])
                                                dt.draw();
                                            } else {
                                                toast.error(errorCodeDic['003'])
                                            }
                                        }).catch(error => {
                                            toast.error(errorCodeDic['003']);
                                            console.log('error: ', error);
                                        })
                                            .finally(() => {
                                                spinner.stop();
                                            });
                                    },
                                }],
                            },


                        ]}
                        checked={false}
                        index={true}

                        onLoad={(dt: any) => {
                            dt.on('draw', function () {
                                const datas = dt.data();
                                setTableDataList(datas);
                                if (datas.length > 0) {
                                    const rowData = datas[0];
                                    const totTbmCnt = dt.page.info().recordsDisplay
                                    console.log('tbm rowData:', rowData);
                                    let totTbmYCnt = 0;
                                    let totTbmNCnt = 0;
                                    const wrkrCnt = rowData.calTotWrkrCnt;
                                    let wrkrTbmYCnt = 0;
                                    let wrkrTbmNCnt = 0;
                                    if (rowData.calTotWrkrCnt == rowData.tbmCmpltCnt) {
                                        if (rowData.calTotWrkrCnt == 0) {
                                            totTbmNCnt = totTbmCnt;
                                            wrkrTbmNCnt = rowData.calTotWrkrCnt;
                                        } else {
                                            totTbmYCnt = totTbmCnt;
                                            wrkrTbmYCnt = rowData.calTotWrkrCnt;
                                        }
                                    } else {
                                        totTbmNCnt = totTbmCnt;
                                        wrkrTbmNCnt = rowData.calTotWrkrCnt - rowData.tbmCmpltCnt
                                        wrkrTbmYCnt = rowData.tbmCmpltCnt;
                                    }
                                    const param = {
                                        totTbmCnt: totTbmCnt,
                                        totTbmYCnt: totTbmYCnt,
                                        totTbmNCnt: totTbmNCnt,

                                    }
                                    setStatData(param);
                                    if(!CmmnFn.isSmSite()){
                                        setWrkrCnt(wrkrCnt)
                                        setWrkrTbmYCnt(wrkrTbmYCnt)
                                        setWrkrTbmNCnt(wrkrTbmNCnt)
                                    }else{
                                        setWrkrTbmYCnt(wrkrTbmYCnt);
                                    }

                                    setFirstRowData(rowData);
                                } else {
                                    setStatData({
                                        totTbmCnt: 0,
                                        totTbmYCnt: 0,
                                        totTbmNCnt: 0,

                                    })

                                    if(!CmmnFn.isSmSite()){
                                        setWrkrCnt(0)
                                        setWrkrTbmYCnt(0)
                                        setWrkrTbmNCnt(0)
                                    }


                                    setFirstRowData(null);
                                }
                            });

                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>


    );
};

export default SmartTBMManagementPage;
